
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";

.reports-data {
  height: 100%;
  width: 100%;
}

.chart-options {
  padding: 0.25rem;
  line-height: 1;
  label {
    margin: unset;
    margin-left: 0.25rem;
  }
}

.graphing {
  height: calc(100% - 1rem);
  width: 100%;
}
