
div.vqb-group-heading.card-header {
  display: none;
}

select.form-control.mr-2 > button.btn.btn-secondary {
  color: red;
}
// #vqb-match-type.form-control,
// .form-control.mr-2 {
//   display: inline-flex;
//   outline: 0;
//   appearance: none;
//   height: 2.5rem;
//   background-color: #fff;
//   border-color: #dbdbdb;
//   border-radius: 4px;
//   color: #363636;
//   max-width: 100%;
//   min-width: 150px;
//   width: auto;
//   align-items: center;
//   justify-content: center;
//   font-size: 1rem;
//   padding: 0.25rem 1rem;
//   line-height: 1.5;
// }

// #vqb-match-type.form-control:focus,
// .form-control.mr-2 {
//   min-width: 150px;
//   width: auto;
// }
