
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";

.field {
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  label {
    font-weight: 600;
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
  }
}

.section-list {
  grid-area: main;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

.section-item {
  cursor: pointer;
  margin: 0.5rem;
  padding: 0.5rem;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  transition: transform 150ms ease-in-out;
  &__name {
    font-weight: 600;
  }
  &__count {
    font-style: italic;
  }
  &:hover {
    transform: scale(1.025);
  }
}

.section-id {
  font-weight: 600;
  font-style: italic;
}
